<template>
  <!-- 任务发布页 -->
  <div class="task-release-container">
    <div class="taskrelease-BtnContainer">
      <el-button
        type="primary"
        icon="el-icon-circle-plus-outline"
        class="addBtn"
        @click="addtaskVisible = true"
        >新增</el-button
      >
      <el-input
        placeholder="搜索"
        suffix-icon="el-icon-search"
        v-model="input1"
        class="searchinput"
      >
      </el-input>
    </div>
    <div class="taskreleasetable-container">
      <taskreleasetable-com></taskreleasetable-com>
    </div>

    <addtask-com
      :addtaskVisible="addtaskVisible"
      @addtaskVisibleClose="addtaskVisibleClose"
    ></addtask-com>
  </div>
</template>


<script>
import taskreleasetableCom from '../components/task-release/taskreleasetableCom'
import addtaskCom from '../components/task-release/addtaskCom.vue'


export default {
  data() {
    return {
      input1: '',
      addtaskVisible: false,
      taskdetailsVisible: true
    }
  },
  components: {
    taskreleasetableCom, addtaskCom,
  },
  methods: {
    addtaskVisibleClose() {
      this.addtaskVisible = false
    },

  }
}
</script>


<style lang="scss">
.task-release-container {
  box-sizing: border-box;
  padding: 30px 50px;
  background-color: #f2f2f2;
  height: 100%;
  .taskrelease-BtnContainer {
    text-align: left;
    margin-bottom: 50px;
    .addBtn {
      background-color: $index-buttonPor-color;
      font-size: 16px;
      border: none;
    }
    .searchinput {
      width: 300px;
      margin-left: 20px;
    }
  }
  .taskreleasetable-container {
    overflow: hidden;
  }
}
</style>  