<template>
  <div>
    <!-- 任务管理--任务发布--新增dialog -->
    <el-dialog
      width="30%"
      title="新增日期"
      :visible.sync="addtaskVisible"
      append-to-body
      @close="addtaskVisibleClose"
      :before-close="addtaskVisibleClose"
      custom-class="addtask-dialog"
      top="10%"
    >
      <div class="addtask-container">
        <div class="addtask-gridContainer">
          <!-- 发布人 -->
          <div class="addtask-gridItem">
            <span class="addtask-gridItem-title">发布人:</span>
            <el-input v-model="input" placeholder="请输入内容"></el-input>
          </div>
          <!-- 接收人 -->
          <div class="addtask-gridItem">
            <span class="addtask-gridItem-title">接收人:</span>
            <el-input v-model="input" placeholder="请输入内容"></el-input>
          </div>
          <!-- 公司名称 -->
          <div class="addtask-gridItem">
            <span class="addtask-gridItem-title">公司名称:</span>
            <el-input v-model="input" placeholder="请输入内容"></el-input>
          </div>
          <!-- 业务类型 -->
          <div class="addtask-gridItem">
            <span class="addtask-gridItem-title">业务类型:</span>
            <el-autocomplete
              class="inline-input"
              v-model="state1"
              :fetch-suggestions="querySearch"
              placeholder="请输入内容"
              @select="handleSelect"
            ></el-autocomplete>
          </div>
          <!-- 发布日期 -->
          <div class="addtask-gridItem">
            <span class="addtask-gridItem-title">发布日期:</span>
            <el-date-picker
              v-model="time1"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </div>
          <!-- 到期日期 -->
          <div class="addtask-gridItem">
            <span class="addtask-gridItem-title">到期日期:</span>
            <el-date-picker
              v-model="time2"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </div>
          <!-- 是否抄送 -->
          <div class="addtask-gridItem addtask-biggridItem">
            <span class="addtask-gridItem-title">是否抄送:</span>
            <div class="addtask-gridradioContaine">
              <el-radio v-model="radio" label="1">是</el-radio>
              <el-radio v-model="radio" label="2">否</el-radio>
            </div>
          </div>
          <!-- 抄送人 -->
          <div class="addtask-gridItem addtask-biggridItem addtaskflex-top">
            <span class="addtask-gridItem-title">抄送人:</span>

            <div class="addtask-gridradioContaine">
              <el-input v-model="input" placeholder="请输入内容"></el-input>
              <div class="addtask-raido" style="margintop: 20px">
                <!-- 此处应该要用输入框来动态生成 -->
                <el-radio v-model="radio1" label="1" border>备选项1</el-radio>
                <el-radio v-model="radio1" label="2" border>备选项2</el-radio>
              </div>
            </div>
          </div>
          <!-- 任务详情 -->
          <div class="addtask-gridItem addtask-biggridItem">
            <span class="addtask-gridItem-title">任务详情:</span>
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              placeholder="请输入内容"
              v-model="textarea2"
            >
            </el-input>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addtaskVisibleClose()">取消</el-button>
        <el-button @click="addtask()" class="resBtn">添加</el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>
export default {
  data() {
    return {
      input: '',
      value: '',
      dialogImageUrl: '',
      imgdialogVisible: false,
      disabled: false,
      value1: '',
      //   业务类型
      restaurants: [],
      state1: '',
      //   时间
      time1: '',
      time2: '',
      textarea2: '',
      radio: '',
      radio1: ''
    }
  },
  props: {
    addtaskVisible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleRemove(file) {
      console.log(file);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.imgdialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    },
    addtask() {
      
    },
    addtaskVisibleClose() {
      // console.log('添加dialog被关闭')
      this.$emit('addtaskVisibleClose')
    },
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    loadAll() {
      return [
        { "value": "三全鲜食（北新泾店）", "address": "长宁区新渔路144号" },
        { "value": "Hot honey 首尔炸鸡（仙霞路）", "address": "上海市长宁区淞虹路661号" },

      ];
    },
    handleSelect(item) {
      console.log(item);
    }
  },
  mounted() {
    this.restaurants = this.loadAll();
  }
}
</script>

<style lang="scss">
.addtask-dialog {
  .el-dialog__header {
    background-color: $index-buttonPor-color;
    font-size: 20px;
    font-weight: bold;
    .el-dialog__title {
      color: #ffffff;
      font-size: 20px;
      font-weight: bold;
    }
    .el-dialog__headerbtn {
      font-size: 20px;
      color: #ffffff;
      .el-icon-close {
        color: #ffffff;
      }
    }
  }
  .el-dialog__body {
    height: 600px;
    overflow: auto;
    padding: 20px;
    .addtask-gridContainer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 20px;
      grid-column-gap: 20px;
      //   border-bottom: 2px solid #f2f2f2;
      //   padding-bottom: 30px;
      .addtaskflex-top {
        align-items: flex-start !important;
      }
      .addtask-biggridItem {
        grid-column-start: span 2;
      }
      .addtask-gridItem {
        display: flex;
        align-items: center;
        .addtask-gridItem-title {
          width: 100px;
        }
        .el-input,
        .el-autocomplete {
          flex: 1;
        }
      }
    }
  }
  .el-dialog__footer {
    .dialog-footer {
      .el-button {
        border: 1px solid $index-buttonPor-color;
        color: $index-buttonPor-color;
      }
      .resBtn {
        background-color: $index-buttonPor-color;
        color: #ffffff;
      }
    }
  }
}
</style>
