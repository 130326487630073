<template>
  <div>
    <!-- 任务发布-任务详情 -->
    <el-dialog
      width="30%"
      title="任务详情"
      :visible.sync="taskdetailsVisible"
      append-to-body
      @close="taskdetailsVisibleClose"
      :before-close="taskdetailsVisibleClose"
      custom-class="taskdetails-dialog"
      top="10%"
    >
      <div class="taskdetails-container">
        <div class="taskdetails-gridContainer">
          <!-- 业务类型 -->
          <div class="taskdetails-gridItem">
            <span class="taskdetails-gridItem-title">业务类型:</span>
            <span>{{ rowdata.business_type }}</span>
          </div>
          <!-- 公司名称 -->
          <div class="taskdetails-gridItem">
            <span class="taskdetails-gridItem-title">公司名称:</span>
            <span>{{ rowdata.corporate_name }}</span>
          </div>
          <!-- 发布人 -->
          <div class="taskdetails-gridItem">
            <span class="taskdetails-gridItem-title">发布人:</span>
            <span>{{ rowdata.publisher }}</span>
          </div>
          <!-- 接收人 -->
          <div class="taskdetails-gridItem">
            <span class="taskdetails-gridItem-title">接收人:</span>
            <span>{{ rowdata.receiver }}</span>
          </div>
          <!-- 发布日期 -->
          <div class="taskdetails-gridItem">
            <span class="taskdetails-gridItem-title">发布日期:</span>
            <span>{{ rowdata.expiration_time }}</span>
          </div>
          <!-- 到期日期 -->
          <div class="taskdetails-gridItem">
            <span class="taskdetails-gridItem-title">到期日期:</span>
            <span>{{ rowdata.release_time }}</span>
          </div>
          <!-- 任务详细 -->
          <div class="taskdetails-gridItem taskdetails-biggridItem">
            <span class="taskdetails-gridItem-title">任务详细:</span>
            <span>{{ rowdata.task_details }}</span>
          </div>
        </div>
        <div class="taskdetails-stateContainer">
          <div class="taskdetails-statetitle">任务状态:</div>
          <el-steps :active="2" align-center finish-status="success">
            <el-step title="分配中"></el-step>
            <el-step title="进行中"></el-step>
            <el-step title="暂停"></el-step>
            <el-step title="已完结"></el-step>
          </el-steps>
        </div>
        <div class="taskdetails-psContainer">
          <div class="taskdetails-statetitle">备注内容:</div>
          <div class="taskdetails-psitem">
            <div class="psleft">
              <img src="../../assets/taskavaimg.png" alt="" class="psavaimg" />
              <div class="ps-content">
                <div class="contenttitle">
                  <span>天灿灿</span><span>2020-02-28 15:34:28</span>
                </div>
                <div class="dask">用户已通过转账转账22400元</div>
              </div>
            </div>
            <div class="addimgBtn">
              <el-button class="addbtn">添加图片</el-button>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>


<script>
export default {
  data() {
    return {

    }
  },
  props: {
    taskdetailsVisible: {
      type: Boolean,
      default: false
    },
    rowdata: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  methods: {
    taskdetailsVisibleClose() {
      console.log('内置dialog被关闭')
      this.$emit('taskdetailsVisibleClose')
    },
  },
}
</script>

<style lang="scss">
.taskdetails-dialog {
  .taskdetails-psitem {
    display: flex;
    padding: 20px;
    justify-content: space-between;
    .psleft {
      display: flex;
      .psavaimg {
        width: 50px;
        height: 50px;
        border-radius: 5px;
        margin-right: 20px;
      }
      .contenttitle {
        span:first-child {
          margin-right: 20px;
        }
      }
    }
    .addimgBtn {
      .addbtn {
        background-color: $index-buttonPor-color;
        color: #ffffff;
      }
    }
  }
  .el-dialog__header {
    background-color: $index-buttonPor-color;
    font-size: 20px;
    font-weight: bold;
    .el-dialog__title {
      color: #ffffff;
      font-size: 20px;
      font-weight: bold;
    }
    .el-dialog__headerbtn {
      font-size: 20px;
      color: #ffffff;
      .el-icon-close {
        color: #ffffff;
      }
    }
  }
  .el-dialog__body {
    height: 600px;
    overflow: auto;
    padding: 20px;
    .taskdetails-gridContainer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 20px;
      grid-column-gap: 20px;
      border-bottom: 2px solid #f2f2f2;
      padding-bottom: 30px;
      .taskdetailsflex-top {
        align-items: flex-start !important;
      }
      .taskdetails-biggridItem {
        grid-column-start: span 2;
      }
      .taskdetails-gridItem {
        display: flex;
        align-items: center;
        .taskdetails-gridItem-title {
          width: 100px;
        }
        .el-input,
        .el-autocomplete {
          flex: 1;
        }
      }
    }
    .taskdetails-stateContainer {
      padding: 30px 0;
      border-bottom: 2px solid #f2f2f2;
      .taskdetails-statetitle {
        margin-bottom: 20px;
      }
    }
    .taskdetails-psContainer {
      padding: 30px 0;
    }
  }
  .el-dialog__footer {
    .dialog-footer {
      .el-button {
        border: 1px solid $index-buttonPor-color;
        color: $index-buttonPor-color;
      }
      .resBtn {
        background-color: $index-buttonPor-color;
        color: #ffffff;
      }
    }
  }
}
</style>
